import React from 'react';

const CustomForm = ({formData,icons,change,clear,save}) => {
    return(
        <form onSubmit={save}>
            <div className="row">
                <div className="input-field col s12">
                    <input id="comp_name" name='comp_name' type="text" value={formData.comp_name} onChange={change.bind(null,null)} required autoComplete='off' className="validate autocomplete" />
                    <label className={formData.comp_name !== '' ? 'active' : ''} htmlFor="comp_name">Company Name</label>
                </div>
                <div className="input-field col s12">
                    <input id="bg_color" name="bg_color" value={formData.bg_color} onChange={change.bind(null,null)} type="text" required className="validate" />
                    <label className={formData.bg_color !== '' ? 'active' : ''} htmlFor="bg_color">Background Color</label>
                </div>
                <div className="input-field col s12">
                    <input id="icon_color" name="icon_color" value={formData.icon_color} onChange={change.bind(null,null)} type="text" required className="validate" />
                    <label className={formData.icon_color !== '' ? 'active' : ''} htmlFor="icon_color">Icon Color</label>
                </div>
                <div className="input-field col s12">
                    <label>
                        <input id='show_text' name='show_text' checked={formData.show_text} onChange={change.bind(null,null)} type="checkbox" />
                        <span>Show Text</span>
                    </label>
                </div>
                <div className="input-field col s12" style={{marginTop:'3rem'}}>
                    <span>Choose Icons</span>
                    <ul className="collapsible">
                        {icons.length !== 0 ?
                        icons.map((icon,index) => {
                            return (
                                <li key={icon.id}>
                                    <div className="collapsible-header">{icon.type}</div>
                                    <div className="collapsible-body">
                                        <div className='row'>
                                            <div className="input-field col s12" style={{marginBottom:'3rem'}}>
                                                <label>
                                                    <input id={icon.type+'_include'} 
                                                    disabled={formData.comp_id !== 0}
                                                    checked={icon.include} 
                                                    name='include' 
                                                    onChange={change.bind(null,index)} type="checkbox" />
                                                    <span>Include</span>
                                                </label>
                                            </div>
                                            <div className="input-field col s12">
                                                <input id={icon.type+'_text'} name='text' required={icon.include} value={icon.text} onChange={change.bind(null,index)} type="text" className="validate" />
                                                <label className={icon.text !== '' ? 'active' : ''} htmlFor={icon.type+'_text'}>Text</label>
                                            </div>
                                            <div className="input-field col s12">
                                                <input id={icon.type+'_link'} name='link' required={icon.include} value={icon.link} onChange={change.bind(null,index)} type="text" className="validate" />
                                                <label className={icon.link !== '' ? 'active' : ''} htmlFor={icon.type+'_link'}>Link</label>
                                            </div>
                                            <div className="input-field col s12">
                                                <input id={icon.type+'_order'} name='order' pattern='^[0-9]+$' required={icon.include} value={icon.order} onChange={change.bind(null,index)} type="text" className="validate" />
                                                <label className={icon.order !== '' ? 'active' : ''} htmlFor={icon.type+'_order'}>Order</label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        }) : <h3>Icons not available yet!</h3>}
                    </ul>
                </div>
                <div className="input-field col s12">
                    <button className="btn waves-effect waves-light FormBtn" type="submit" name="save">Save
                        <i className="material-icons right">cloud_upload</i>
                    </button>
                    <button className="btn waves-effect waves-light FormBtn" onClick={clear} type="button" name="clear">Clear
                        <i className="material-icons right">clear_all</i>
                    </button>
                </div>               
            </div>
        </form>
    );
}

export default CustomForm;