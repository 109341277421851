import React from 'react';
import M from 'materialize-css';

import './dashboard.css';
import CustomTable from '../customComponents/customTable/customTable';
import CustomForm from '../customComponents/customForm';
import Spinner from '../customComponents/spinner/spinner'
import {Link} from 'react-router-dom';
import {cacheHeaders} from '../config';

class DashBoard extends React.Component{

    state={
        listData:[],
        prevSelectedRow:0,
        currSelectedRow: 0,
        currSelectedName:'',
        columns:[
            {
              Header: "ID",
              accessor: "comp_id",
              filter: "text"
            },
            {
              Header: "Name",
              accessor: "comp_name",
              filter: "text"
            },
            {
                Header: "Background",
                accessor: "bg_color",
                filter: "text"
            },
            {
              Header: "Icon-color",
              accessor: "icon_color",
              filter: "text"
            },
            {
                Header: "Icons",
                accessor: "text",
                filter: "text"
            },
            {
                Header: "Icon-Links",
                accessor: "link",
                filter: "text"
            }
        ],
        initialIcons:[],
        initialFormData:{
            comp_id:0,
            comp_name:'',
            bg_color:'',
            icon_color:'',
            show_text: true,
        },
        iconsList:[],
        formData:{
            comp_id:0,
            comp_name:'',
            bg_color:'',
            icon_color:'',
            show_text: true,
        },
        formType: 'Add',
        isLoading: true,
        hasError: false,
        isFormOpen: false
    }

    // loginRequired = ()=>{
    //     this.props.authe
    // }

    formOpenHandler = open => {
        this.setState({isFormOpen:open});
    }

    clearFormData = () =>{
        if(this.state.initialIcons.length === 0){
            fetch('https://breezetact.breezereview.com/breezetact/dashboard/icons',{
                headers: { 
                    ...cacheHeaders,
                    'Authorization': 'Bearer '+ localStorage.getItem('authToken')
                 }
            })
            .then(res => {
                if(res.ok){
                    return res.json()
                }else{
                    return Promise.reject(new Error(res.status));
                }
                
            })
            .then(data => {
                    this.setState({
                        initialIcons:data,
                        iconsList:data,
                        formData:{...this.state.initialFormData},
                        formType:'Add',
                        prevSelectedRow:0,
                        currSelectedRow:0
                    });
                }
            )
            .catch(err => {
                if(+err.message && +err.message > 400 && +err.message < 500){
                    M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                    this.props.history.push('/login');
                }else{
                    M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
                }
            });
        }else{
            this.setState({
                formData:{...this.state.initialFormData},
                iconsList:[...this.state.initialIcons],
                formType:'Add',
                prevSelectedRow:0,
                currSelectedRow:0
            });
        }
    }

    toast = (data) =>{
        let classes=['rounded'];
        classes.push(data.success?'ToastSuccess':'ToastFailure');
        M.toast({html: data.message, classes: classes.join(' ')});
    } 

    rowSelectHandler = (id,name) => {
        if(id !== this.state.currSelectedRow){
            this.setState({currSelectedRow:id,currSelectedName:name});
        }
    }

    addHandler = () => {
        this.clearFormData();
        this.formOpenHandler(true);
    }

    editHandler = () => {
        let id = this.state.currSelectedRow;
        // To prevent repeated API call for the same row
        if(id !== 0 && id !== this.state.prevSelectedRow){
            fetch('https://breezetact.breezereview.com/breezetact/dashboard/icons/'+id,{
                headers: { 
                    ...cacheHeaders,
                    'Authorization': 'Bearer '+ localStorage.getItem('authToken')
                 }
            })
            .then(res => {
                if(res.ok){
                    return res.json()
                }else{
                    return Promise.reject(new Error(res.status));
                }
                
            })
            .then(data => {
                    this.setState({formData:data.company,iconsList:data.icons,formType:'Edit',isFormOpen:true,prevSelectedRow:id});
                }
            )
            .catch(err => {
                if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
                }else{
                    M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
                }}); 
        }  
    }

    deleteHandler = () => {
        fetch('https://breezetact.breezereview.com/breezetact/dashboard/'+ this.state.currSelectedRow,{
            method: 'delete',
            headers: {
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
            },
        })
        .then(res => {
            if(res.ok){
                return res.json()
            }else{
                return Promise.reject(new Error(res.status));
            }
            
        })
        .then(data => {
            this.toast(data);
            this.setState({currSelectedRow:0,prevSelectedRow:0,currSelectedName:''});
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
            }
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        const postData = {
            company: this.state.formData,
            icons: this.state.iconsList
        }
        fetch('https://breezetact.breezereview.com/breezetact/dashboard/',{
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
                ...cacheHeaders
            },
            body: JSON.stringify(postData)
        })
        .then(res => {
            if(res.ok){
                return res.json()
            }else{
                return Promise.reject(new Error(res.status));
            }
            
        })
        .then(data => {
            this.toast(data);
            if(data.success){
                this.formOpenHandler(false);
                this.clearFormData();
            }
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An error occurred and the action could not be completed!', classes: 'rounded ToastFailure'});
            }
        });
    }

    inputChangeHandler = (index,event) =>{
        let value='';
        value = event.target.type === 'checkbox'? event.target.checked : event.target.value;
        if(index !== null){
            const iconData = [...this.state.iconsList];
            let icon = {...iconData[index]};
            icon[event.target.name] = value;
            iconData[index] = icon;
            this.setState({iconsList:iconData});
        }else{
            const formData = {...this.state.formData};
            formData[event.target.name] = value;
            this.setState({formData:formData});
        }      
    }

    componentDidMount(){
        fetch('https://breezetact.breezereview.com/breezetact/dashboard',{
            headers: { 
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.getItem('authToken')
             }
        })
        .then(res => {
            if(res.ok){
                return res.json()
            }else{
                return Promise.reject(new Error(res.status));
            }
            
        })
        .then(data => {
                this.setState({listData:data,isLoading:false});
                // For auto completing Company Names
                let autocompleteData={};
                data.forEach(elem => {autocompleteData[elem.comp_name] = null;} );
                const elems = document.querySelectorAll('.autocomplete');
                const instances = M.Autocomplete.init(elems, {data:autocompleteData});
                M.AutoInit();
            }
        )
        .catch(err => {
            M.AutoInit();
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An Error Occurred', classes: 'rounded ToastFailure'});
                this.setState({hasError:true});
            }
        });        
    }

    render(){
        let formClasses=['FormDiv','card-panel'];
        if(this.state.isFormOpen){
            formClasses.push('FormOpen');
        }
        let content = <Spinner />
        if(!this.state.isLoading){
            if(this.state.hasError){
                content = <h3>Data Not Available</h3>
            }else{
                content = <CustomTable columns={this.state.columns} data={this.state.listData} select={this.rowSelectHandler} pagination filter />
            }
        }
        return(
            <div className='DashBoard card-panel'>
                <div className='TableContainer'>
                    {content}
                </div>
                <div className='ControlPanel'>
                    <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped" data-tooltip='Add Company' onClick={this.addHandler}><i className="material-icons">add</i></button>
                    <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped" data-tooltip='View Report' disabled={this.state.currSelectedRow===0} onClick={() => this.props.viewReport(this.state.currSelectedRow,this.state.currSelectedName)} >
                        <Link to={this.props.location.pathname+'/report'}>
                            <i className="material-icons">visibility</i>
                        </Link>
                    </button>
                    <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped" data-tooltip='Edit Company' disabled={this.state.currSelectedRow===0} onClick={this.editHandler} ><i className="material-icons">edit</i></button>
                    <button className="btn-floating waves-effect waves-light ctrlBtn tooltipped modal-trigger" data-target='delete_modal' data-tooltip='Delete Company' disabled={this.state.currSelectedRow===0} ><i className="material-icons">delete</i></button>
                </div>
                <div className={formClasses.join(' ')}>
                    <div className='FormHeader'>
                        <span>{this.state.formType+' Company'}</span>
                        <i onClick={() => {this.formOpenHandler(false);this.clearFormData()}} className="material-icons">close</i>
                    </div>
                    <CustomForm
                    icons={this.state.iconsList}
                    formData={this.state.formData}
                    change={this.inputChangeHandler}
                    clear={this.clearFormData}
                    save={this.submitHandler} />
                </div>
                <div id="delete_modal" className="modal">
                    <div className="modal-content">
                    <h4>Delete Entity</h4>
                    <p>Are you sure you want to delete this company?<br/>
                        <b>{this.state.currSelectedRow + ' - ' + this.state.currSelectedName}</b>
                    </p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn modal-close waves-effect waves-green" onClick={this.deleteHandler}>Yes</button>
                        <button className="btn modal-close waves-effect waves-red">No</button>
                    </div>
                </div>    
            </div>
        )
    }
}

export default DashBoard;